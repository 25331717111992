import axios from "axios";
const projectEnv = process.env.NODE_ENV;
const stage_back = 'https://api-stage-paribahis-az-income.go.dogt.xyz/'
const prod_back = 'https://admin.paribahis-az.partners/'
let baseURL = null;

// switch (projectEnv) {
//   case "development":
//     baseURL = stage_back
//     break;
//   case "local-development":
//     baseURL = stage_back
//     break;
//   case "stage":
//     baseURL = stage_back
//     break;
//   default:
//     baseURL = prod_back
// }

if (process.env.REACT_APP_INCOME_BACKEND_URL) {
  baseURL = process.env.REACT_APP_INCOME_BACKEND_URL;
}

console.log("===env", process.env.REACT_APP_INCOME_BACKEND_URL)
console.log("===baseURL", baseURL)
const axiosClient = axios.create({
  baseURL,
  responseType: "json",
});

export default axiosClient;
